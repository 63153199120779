import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {"environment":"production","appVersion":"blog-2024-9-30","apiKey":"326f0dbdffebebd6d90db6f9db8435d7","autoDetectErrors":true,"autoTrackSessions":true,"enabledReleaseStages":["development","production"],"metadata":{"site":{"name":"blog","url":"http:\u002F\u002Fundefined:undefined"}},"publishRelease":true,"releaseStage":"production"}
options.plugins = [new BugsnagPluginVue()]

let bugsnagClient

export default function ({ app }, inject) {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(options)
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}
